const selfSimulation = {
  id: 1,
  name: "AUTO_SIMULACAO",
  description: "Auto Simulação"
};

const thirdPartySimulation = {
  id: 2,
  name: "SIMULACAO_POR_TERCEIRO",
  description: "Simulação em atendimento"
};

const partnerSimulation = {
  id: 3,
  name: "SIMULACAO_POR_TERCEIRO",
  description: "Simulação em Atendimento Parceiro"
};

const ctpsSimulation = {
  id: 4,
  name: "SIMULACAO_CTPS_DIGITAL",
  description: "Simulação CTPS Digital"
};

/*
 * Enum
 */

export const LoanSimulationTypeEnum = {
  SELF_SIMULATION: selfSimulation,
  THIRD_PARTY_SIMULATION: thirdPartySimulation,
  PARTNER_SIMULATION: partnerSimulation,
  CTPS_SIMULATION: ctpsSimulation
};

