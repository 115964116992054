
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import { isEmpty } from "lodash";
import Modal from "@/components/Modal.vue";
import { mask } from "vue-the-mask";
import SaveButton from "@/components/SaveButton.vue";
import LoanInitialData from "./LoanInitialData.vue";
import LoanCalculate from "./LoanCalculate.vue";
import LoanPersonalData from "./LoanPersonalData.vue";
import LoanUploadDocuments from "./LoanUploadDocuments.vue";
import LoanResume from "./LoanResume.vue";
import LoanType from "./LoanType.vue";
import { SaveLoanResponse } from "@/services/loan-service";

enum LoanFlowStepEnum {
  _LoanInitialData = 0,
  LoanType = 1,
  LoanCalculate = 2,
  LoanPersonalData = 3,
  LoanUploadDocuments = 4,
  LoanResume = 5
}

@Component({
  components: {
    ValidationObserver,
    Modal,
    SaveButton,
    LoanInitialData,
    LoanCalculate,
    LoanPersonalData,
    LoanUploadDocuments,
    LoanResume,
    LoanType
  },
  directives: { mask },
  computed: {
    isMobile() {
      return (this as any).screenWidth < 1024;
    }
  },

  mounted() {
    (this as any).setupWindowResizeHandling();
  }
})
export default class LoanFlowModal extends Vue {
  @Prop() show!: boolean;
  @Prop({ type: String, default: "" }) currentCpf!: string;
  @Prop({ type: Number || null, default: null  }) currentLoanId!: number; 


  LoanFlowStepEnum = LoanFlowStepEnum;

  screenWidth = 0;
  step: number = LoanFlowStepEnum._LoanInitialData;
  subStep: number = 0;
  caches = {} as { [step: number]: any };
  savedLoan: SaveLoanResponse | null = null;

  /** Used to track whether there are unsaved changes in the current step and display a confirmation dialog when user tries to leave the step. */
  hasUnsavedChanges: boolean = false;

  unconsideredContractValues: number;

  /** Used to prevent the "unsaved changes" confirmation dialog from being displayed infinitely (due to "step" property's watcher) */
  confirmingStepChange = false;

  /** Used to save step's state during re-renders caused by the unsaved changes confirmation behavior */
  states = {
    [LoanFlowStepEnum._LoanInitialData]: {},
    [LoanFlowStepEnum.LoanType]: {},
    [LoanFlowStepEnum.LoanCalculate]: {},
    [LoanFlowStepEnum.LoanPersonalData]: {},
    [LoanFlowStepEnum.LoanUploadDocuments]: {},
    [LoanFlowStepEnum.LoanResume]: {}
  };

  /** Used to disable step navigation after the flow has been finished */
  isNavigationDisabled: boolean = false;

  constructor() {
    super();
  }

  @Watch("step")
  watchStep(newStep: LoanFlowStepEnum, oldStep: LoanFlowStepEnum): void {
    if (
      !this.confirmingStepChange &&
      newStep !== oldStep &&
      this.hasUnsavedChanges
    ) {
      this.confirmStepChange(newStep, oldStep);
    } else {
      this.confirmingStepChange = false;
      this.states[oldStep] = {};
    }
  }

  updatedLoan(updatedLoan: SaveLoanResponse): void {
    this.savedLoan = updatedLoan;
  }

  next(): void {
    this.hasUnsavedChanges = false;
    this.step++;
  }

  previous(): void {
    this.hasUnsavedChanges = false;
    this.step--;
    if (this.step === LoanFlowStepEnum._LoanInitialData) {
      this.savedLoan = null;
    }
  }

  get modalTitle(): string {
    return this.savedLoan?.loan?.id
      ? `Empréstimo ${this.savedLoan?.loan?.id} - Continuar`
      : "Novo empréstimo";
  }

  get titles(): { [step: number]: string } {
    return {
      [LoanFlowStepEnum.LoanType]: "Tipo de empréstimo",
      [LoanFlowStepEnum.LoanCalculate]: "Calculadora",
      [LoanFlowStepEnum.LoanPersonalData]: "Dados cadastrais",
      [LoanFlowStepEnum.LoanUploadDocuments]: "Pagamento e Documentação",
      [LoanFlowStepEnum.LoanResume]: "Confirmação"
    };
  }

  isComplete(step: LoanFlowStepEnum): boolean {
    let result;

    const defaultCondition =
      !isEmpty(this.savedLoan?.borrower) &&
      !isEmpty(this.savedLoan?.loan) &&
      !isEmpty(this.savedLoan?.marginBaseRegister);

    const completedLoanType = !!this.savedLoan?.loan?.typeId;
    const completedLoanCalculate =
      !!this.savedLoan?.loan?.requestedAmount &&
      !!this.savedLoan?.loan?.numInstallments &&
      !!this.savedLoan?.loan?.marginBaseRegisterId;
    const completedLoanPersonalData =
      !!this.savedLoan?.borrower.birthDate &&
      !!this.savedLoan?.borrower.address;
    const completedLoanUploadDocuments = !isEmpty(
      this.savedLoan?.loan?.documents
    );
    const completedLoanResume = !isEmpty(this.savedLoan?.loan?.installments);

    switch (step) {
      case LoanFlowStepEnum.LoanType:
        result = defaultCondition && completedLoanType;
        break;
      case LoanFlowStepEnum.LoanCalculate:
        result =
          defaultCondition && completedLoanType && completedLoanCalculate;
        break;
      case LoanFlowStepEnum.LoanPersonalData:
        result =
          defaultCondition &&
          completedLoanType &&
          completedLoanCalculate &&
          completedLoanPersonalData;
        break;
      case LoanFlowStepEnum.LoanUploadDocuments:
        result =
          defaultCondition &&
          completedLoanType &&
          completedLoanCalculate &&
          completedLoanPersonalData &&
          completedLoanUploadDocuments;
        break;
      case LoanFlowStepEnum.LoanResume:
        result =
          defaultCondition &&
          completedLoanType &&
          completedLoanCalculate &&
          completedLoanPersonalData &&
          completedLoanUploadDocuments &&
          completedLoanResume;
        break;
      default:
        result = false;
        break;
    }

    return result;
  }

  isEditable(step: LoanFlowStepEnum): boolean {
    let result;

    switch (step) {
      case LoanFlowStepEnum.LoanType:
        result = !this.isNavigationDisabled;
        break;
      default:
        result =
          !this.isNavigationDisabled &&
          (this.isComplete(step) || this.isComplete(step - 1));
        break;
    }

    return result;
  }

  handleUnsavedChanges(): void {
    this.hasUnsavedChanges = true;
  }

  handleUnconsideredContractsValues(value) {
    this.unconsideredContractValues = value;
  }

  handleDisableNavigation(): void {
    this.isNavigationDisabled = true;
  }

  handleUpdateEditState(step: LoanFlowStepEnum, state: any): void {
    this.states[step] = state;
  }

  confirmStepChange(
    newStep: LoanFlowStepEnum,
    oldStep: LoanFlowStepEnum
  ): void {
    this.confirmingStepChange = true;
    if (
      confirm(
        "Tem certeza que deseja sair? Você tem alterações não salvas neste passo."
      )
    ) {
      this.hasUnsavedChanges = false;
      this.confirmingStepChange = false;
      this.step = newStep;
      this.states[oldStep] = {};
    } else {
      this.$nextTick(() => {
        this.step = oldStep;
      });
    }
  }

  resetCaches(): void {
    this.caches = Object.fromEntries(
      Object.values(LoanFlowStepEnum)
        .filter((v) => !isNaN(Number(v)))
        .map((step) => [step, {}])
    );
  }

  getCache(step: LoanFlowStepEnum): any {
    return this.caches[step];
  }

  saveCache(step: LoanFlowStepEnum, data: any): void {
    this.caches[step] = data;
  }

  setupWindowResizeHandling(): void {
    this.screenWidth = window.innerWidth;

    let timeout: NodeJS.Timeout;
    window.onresize = () => {
      clearTimeout(timeout);
      timeout = setTimeout(this.onResizeWindow, 100);
    };
  }

  onResizeWindow(): void {
    this.screenWidth = window.innerWidth;
  }
}
