export default interface MarginBaseType {
  id: number;

  name: string;

  created_at?: Date;
  updated_at?: Date;
  deleted_at?: Date;
}

const importType: MarginBaseType = {
  id: 1,
  name: "IMPORTACAO"
};

const consignetType: MarginBaseType = {
  id: 2,
  name: "CONSIGNET"
};

const manualType: MarginBaseType = {
  id: 3,
  name: "MANUAL"
};

const eConsinadoType: MarginBaseType = {
  id: 4,
  name: "E-CONSIGNADO"
};

export const MarginBaseTypeEnum = {
  IMPORT: importType,
  CONSIGNET: consignetType,
  MANUAL: manualType,
  ECONSIGNADO: eConsinadoType
};
