
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import isEmpty from "lodash/isEmpty";
import cloneDeep from "lodash/cloneDeep";
import Modal from "@/components/Modal.vue";
import LoanService, { SaveLoanInitialData } from "@/services/loan-service";
import MarginBaseService from "@/services/margin-base-service";
import { mask } from "vue-the-mask";
import SaveButton from "@/components/SaveButton.vue";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import { checkIfLoanFlowCacheIsRecent } from "@/utils/checkIfLoanFlowCacheIsRecent";
import getErrorMessageForMobilePhoneValidation from "@/utils/getErrorMessageForMobilePhoneValidation";

interface State {
  form: any;
  cpfValidated: boolean;
  loadingCPFValidation: boolean;
  saving: boolean;
}

interface Cache extends State {
  _cacheDate: Date;
}

@Component({
  components: { ValidationObserver, Modal, SaveButton },
  directives: { mask }
})
export default class LoanInitialData extends Vue {
  @Prop({ type: String, default: "" }) currentCpf!: string;
  @Prop({ type: Number || null, default: null }) currentLoanId!: number;
  @Prop({ type: Object, default: () => ({}) }) cache!: Cache;
  /** Used to preserve state (i.e., all relevant data) during re-renders caused by reactive behavior */
  @Prop() readonly editState!: State;
  /** Used on component creation to avoid treating programmatic changes to form as if they were actual user input */
  isProgrammaticFormInput: boolean = false;
  loading: boolean = false;
  loanService: LoanService;
  marginBaseService: MarginBaseService;
  cpfValidated: boolean = false;
  loadingCPFValidation: boolean = false;
  saving: boolean = false;
  getErrorMessageForMobilePhoneValidation =
    getErrorMessageForMobilePhoneValidation;

  emptyForm: SaveLoanInitialData = {
    user: {
      name: "",
      email: "",
      phone: ""
    },
    borrower: {
      name: "",
      cpf: "",
      phone: ""
    },
    loan: {
      id: 0
    },
    shouldSaveHistory: true
  };
  form: SaveLoanInitialData = cloneDeep(this.emptyForm);

  constructor() {
    super();

    this.loanService = LoanService.getInstance();
    this.marginBaseService = MarginBaseService.getInstance();
    if (this.currentCpf) {
      this.form.borrower.cpf = this.currentCpf;
    }
  }

  @Watch("form", { deep: true })
  async formChanged(): Promise<void> {
    this.onUnsavedChanges();
  }

  async created(): Promise<void> {
    this.isProgrammaticFormInput = true;

    if (!isEmpty(this.editState)) {
      this.restoreDataFrom(this.editState);
    } else {
      if (this.isCacheValid()) {
        this.restoreDataFrom(this.cache);
      }
    }

    this.$nextTick(() => {
      this.isProgrammaticFormInput = false;
    });
  }

  isCacheValid(): boolean {
    const isCacheRecent = checkIfLoanFlowCacheIsRecent(this.cache?._cacheDate);

    return isCacheRecent && !isEmpty(this.cache) && !isEmpty(this.cache.form);
  }

  saveCache(): void {
    const data: Cache = {
      _cacheDate: new Date(),
      form: this.form,
      cpfValidated: this.cpfValidated,
      loadingCPFValidation: this.loadingCPFValidation,
      saving: this.saving
    };

    this.$emit("loadData", cloneDeep(data));
  }

  restoreDataFrom(data: State | Cache): void {
    this.form = cloneDeep(data.form);
    this.cpfValidated = cloneDeep(data.cpfValidated);
    this.loadingCPFValidation = cloneDeep(data.loadingCPFValidation);
    this.saving = cloneDeep(data.saving);
  }

  onUnsavedChanges(): void {
    if (!this.isProgrammaticFormInput) {
      this.$emit("unsavedChanges");
    }
    const data: State = {
      form: this.form,
      cpfValidated: this.cpfValidated,
      loadingCPFValidation: this.loadingCPFValidation,
      saving: this.saving
    };
    this.$emit("updateEditState", data);
  }

  async validateCPF(): Promise<void> {
    this.cpfValidated = false;
    if (this.form.borrower.cpf.length < 14) {
      return;
    }

    this.loadingCPFValidation = true;

    const [error, response] = await this.loanService.getSimulation({
      cpf: this.form.borrower.cpf.replace(/\D/g, ""),
      loanId: this.currentLoanId
    });

    this.loadingCPFValidation = false;
    if (error) {
      this.$notify({
        title: "Erro",
        text: getErrorMessageFromApiError(error),
        type: "error"
      });
      return;
    }

    if (response) {
      this.cpfValidated = true;

      if (response.user) {
        this.form.user.name = response.user.name;
        this.form.user.email = response.user.email;
        this.form.user.phone = response.user.phone.replace("+55", "");
      }

      if (response.loan) {
        this.form.loan.id = response.loan.id;
      }

      if (response.borrower) {
        this.form.borrower.name = response.borrower.name;
        this.form.borrower.phone = response.borrower.phone.replace("+55", "");
      }

      this.$emit("updatedLoan", response);
    }
  }

  async save(): Promise<void> {
    this.saving = true;
    const data = cloneDeep(this.form);

    data.borrower.cpf = data.borrower.cpf.replace(/\D/g, "");
    data.borrower.phone = data.borrower.phone.replace(/\D/g, "");
    data.borrower.phone = data.borrower.phone
      ? data.borrower.phone.replace(/\D/g, "")
      : "";

    // Mirror borrower data to user data
    data.user.name = data.borrower.name;
    data.user.phone = data.borrower.phone;
    data.loanId = this.currentLoanId;

    const [error, response] = await this.loanService.saveLoanInitialData(data);

    this.saving = false;
    if (error) {
      this.$notify({
        title: "Erro",
        text: getErrorMessageFromApiError(error),
        type: "error"
      });
      return;
    }

    if (response) {
      this.$notify({
        title: "Sucesso",
        text: "Empréstimo salvo com sucesso.",
        type: "success"
      });

      this.saveCache();
      this.$emit("updatedLoan", response);
      this.goToNextStep();
    }
  }

  onInputCPF() {
    this.cpfValidated = false;
  }

  goToNextStep(): void {
    this.$emit("next");
  }
}
